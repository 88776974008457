/** @jsx jsx */
import { jsx } from 'theme-ui';
import Flex from './flex';
import { containerMarginX } from './const';
import LangToggleButton from './lang-toggle-button';

export default function Footer({
  className,
  lang,
  koUrl,
  enUrl,
  showLangToggleButton = true,
}) {
  return (
    <Flex
      className={className}
      sx={{
        borderTopColor: 'veryLightGray',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderBottomColor: 'veryLightGray',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        py: 18,
        px: containerMarginX,
      }}
    >
      <div
        sx={{
          fontFamily: 'footer',
          fontSize: 14,
          lineHeight: '1em',
          flexShrink: 0,
          color: 'lightGray',
        }}
      >
        © merearchive
      </div>
      <div sx={{ flexGrow: 1 }} />
      {showLangToggleButton && (
        <LangToggleButton lang={lang} enUrl={enUrl} koUrl={koUrl} />
      )}
    </Flex>
  );
}
