/** @jsx jsx */
import { jsx } from 'theme-ui';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';

export default ({ className, node, aspectRatio }) => {
  const originalUrl = imageUrlFor(buildImageObj(node))
    .width(1440)
    .height(1440 * aspectRatio)
    .url();

  const widths = [360, 720, 1440];
  const srcSetWebp = widths.map(width => {
    const url = `${imageUrlFor(buildImageObj(node))
      .width(width)
      .height(width * aspectRatio)
      .url()}&fm=webp`;
    return `${url} ${width}w`;
  });
  const srcSet = widths.map(width => {
    const url = imageUrlFor(buildImageObj(node))
      .width(width)
      .height(width * aspectRatio)
      .url();
    return `${url} ${width}w`;
  });
  const sizes = `(max-width: 1440px) 100vw, 1440px`;

  return (
    <figure className={className}>
      <div
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          aria-hidden={true}
          sx={{
            width: '100%',
            paddingBottom: `${100 * aspectRatio}%`,
          }}
        ></div>
        <picture>
          <source type="image/webp" srcSet={srcSetWebp} sizes={sizes} />
          <source srcSet={srcSet} sizes={sizes} />
          <img
            sizes={sizes}
            srcSet={srcSet}
            src={originalUrl}
            alt={node.alt}
            loading="lazy"
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center',
              opacity: 1,
              color: 'transparent',
              transition: 'none 0s ease 0s',
            }}
          />
        </picture>
      </div>
    </figure>
  );
};
