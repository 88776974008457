/** @jsx jsx */
import { jsx } from 'theme-ui';

export default function Flex({ className, vertical, children }) {
  return (
    <div
      sx={{ display: 'flex', flexDirection: vertical ? 'column' : 'row' }}
      className={className}
    >
      {children}
    </div>
  );
}
