import React from 'react';

export default function Menu({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.00005H0V5.80005H20V7.00005Z"
        fill="#575751"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 17H0V15.8H20V17Z"
        fill="#575751"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12H4V10.8H20V12Z"
        fill="#575751"
      />
    </svg>
  );
}
