/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

const people = [
  {
    label: 'Developed by',
    name: {
      en: 'Eunjae Lee',
      ko: '이은재',
    },
    url: 'https://www.instagram.com/eunjae.dev/',
  },
  {
    label: 'Designed by',
    name: {
      en: 'Minji Jung',
      ko: '정민지',
    },
    url: 'https://www.instagram.com/merearchive/',
  },
];

export default ({ showTitle = true, lang }) => (
  <div>
    {showTitle && (
      <>
        <p
          sx={{
            fontFamily: 'madeByTitle',
            color: 'black',
            fontSize: 16,
            lineHeight: '1em',
          }}
        >
          {lang === 'ko' ? '만든이들' : 'More about the people'}
        </p>
        <div sx={{ mt: 30 }}></div>
      </>
    )}
    <div>
      {people.map(({ label, name, url }) => (
        <p sx={{ mb: 14, '&:last-child': { mb: 0 } }} key={url}>
          <span
            sx={{
              fontFamily: 'madeByLabel',
              fontSize: 16,
              lineHeight: '1em',
              color: 'gray',
            }}
          >
            {label}
          </span>

          <a href={url}>
            <span
              sx={{
                ml: 10,
                fontFamily: 'madeByValue',
                fontSize: 16,
                lineHeight: '1em',
                color: 'lightGray',
              }}
            >
              {name[lang]}
            </span>
          </a>
        </p>
      ))}
    </div>
  </div>
);
