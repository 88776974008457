/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

const SpanOrLink = ({ isSpan, url, className, children }) =>
  isSpan ? (
    <span className={className} sx={{ color: 'black' }}>
      {children}
    </span>
  ) : (
    <Link to={url} className={className} sx={{ color: 'lightGray' }}>
      {children}
    </Link>
  );

export default ({ className, koUrl, enUrl, lang }) => (
  <div className={className}>
    <SpanOrLink
      url={koUrl}
      isSpan={lang === 'ko'}
      sx={{
        flexShrink: 0,
        fontFamily: 'footerKoButton',
        fontSize: 13,
        lineHeight: '1em',
      }}
    >
      한
    </SpanOrLink>
    <span
      sx={{
        mx: 8,
        fontFamily: 'footer',
        fontSize: 14,
        lineHeight: '1em',
        color: 'lightGray',
      }}
    >
      /
    </span>
    <SpanOrLink
      url={enUrl}
      isSpan={lang === 'en'}
      sx={{
        flexShrink: 0,
        fontFamily: 'footerEnButton',
        fontSize: 14,
        lineHeight: '1em',
      }}
    >
      EN
    </SpanOrLink>
  </div>
);
