import React from 'react';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import clientConfig from '../../client-config';

export default ({ className, aspectRatio, node }) => {
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: 1440, maxHeight: aspectRatio ? 1440 * aspectRatio : undefined },
    clientConfig.sanity
  );

  return (
    <figure className={className}>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </figure>
  );
};
