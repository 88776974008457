import React from 'react';

export default function Close({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0004 5.00018L3.05684 19.0001L2.03516 18.043L16.9788 4.04301L18.0004 5.00018Z"
        fill="#2B2C2A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9434 19.0001L1.9998 5.00013L3.02148 4.04297L17.9651 18.0429L16.9434 19.0001Z"
        fill="#2B2C2A"
      />
    </svg>
  );
}
