import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0137 4.09734L21.4348 11.0286L14.0572 17.9403L13.2544 17.1882L19.828 11.0297L13.2122 4.85055L14.0137 4.09734Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83301 10.4497H20.1663V11.5497H1.83301V10.4497Z"
      fill="currentColor"
    />
  </svg>
);
