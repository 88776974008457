import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8.5832H0V7.2832H22V8.5832Z"
      fill="#2B2C2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 19.4172H0V18.1172H22V19.4172Z"
      fill="#2B2C2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9999 14.0002H4.3999V12.7002H21.9999V14.0002Z"
      fill="#2B2C2A"
    />
  </svg>
);
