/** @jsx jsx */
import { jsx, Text, IconButton } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { DropDownMenu, NavBarMenu } from './menu';
import MenuIconMobile from './icon/menu';
import MenuIconTablet from './icon/menu-tablet';
import { NavBarContext } from '../contexts';
import { containerMarginX, navBarHeightsInArray } from '../components/const';
import { useMedia } from '../hooks';

const Breadcrumb = ({ navBarContextValues, renderBreadcrumb }) => (
  <div
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: 'background',
      zIndex: 1,
    }}
  >
    <div
      sx={{
        height: navBarHeightsInArray,
        ...(navBarContextValues.has('lvl2')
          ? {
              mx: containerMarginX.map(m => `calc(${m} - 4px)`),
              px: 4,
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: 'lightGray',
            }
          : { mx: 0, px: containerMarginX }),
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {renderBreadcrumb()}
    </div>
  </div>
);

const NavBar = ({
  lang,
  linkOnLogo,
  showMenu,
  showLangToggleButton,
  koUrl,
  enUrl,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile, isDesktop } = useMedia();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (isMenuOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [lang, isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const MenuIcon = isMobile ? MenuIconMobile : MenuIconTablet;

  return (
    <div>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'background',
          zIndex: 1,
          height: navBarHeightsInArray,
        }}
      >
        <div
          sx={{
            ml: containerMarginX,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {linkOnLogo ? (
            <Link to={`/${lang}`} sx={{ textDecoration: 'none' }}>
              <Text variant="logo">merearchive</Text>
            </Link>
          ) : (
            <Text variant="logo">merearchive</Text>
          )}
        </div>
        {!isDesktop && showMenu && (
          <IconButton
            sx={{
              mt: 14,
              mr: containerMarginX,
              mb: 12,
            }}
            type="button"
            ariaLabel="Menu button"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isDesktop && (
          <NavBarMenu
            lang={lang}
            koUrl={koUrl}
            enUrl={enUrl}
            showLangToggleButton={showLangToggleButton}
          />
        )}
      </div>
      {isMenuOpen && (
        <DropDownMenu
          lang={lang}
          toggleMenu={toggleMenu}
          koUrl={koUrl}
          enUrl={enUrl}
          showLangToggleButton={showLangToggleButton}
          sx={{
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'background',
          }}
        />
      )}
    </div>
  );
};

export default ({
  lang,
  renderBreadcrumb,
  koUrl,
  enUrl,
  showLangToggleButton = true,
  linkOnLogo = true,
  showMenu = true,
}) => {
  return (
    <NavBarContext.Consumer>
      {({ values: navBarContextValues }) => (
        <div>
          {renderBreadcrumb && navBarContextValues.has('lvl1') ? (
            <Breadcrumb
              navBarContextValues={navBarContextValues}
              renderBreadcrumb={renderBreadcrumb}
            />
          ) : (
            <NavBar
              lang={lang}
              linkOnLogo={linkOnLogo}
              showMenu={showMenu}
              showLangToggleButton={showLangToggleButton}
              koUrl={koUrl}
              enUrl={enUrl}
            />
          )}
        </div>
      )}
    </NavBarContext.Consumer>
  );
};
