/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx, Text, IconButton } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import LangToggleButton from './lang-toggle-button';
import CloseIcon from './icon/close';
import { usePathname } from '../hooks';
import MadeBy from './made-by';
import {
  getMenuItems,
  desktopContainerMarginX,
  desktopGrid,
  containerMarginX,
} from './const';

function Link({ title, href, external, className }) {
  if (external) {
    return (
      <a className={className} href={href}>
        <Text variant="menuItem">{title}</Text>
      </a>
    );
  } else {
    return (
      <GatsbyLink className={className} to={href}>
        <Text variant="menuItem">{title}</Text>
      </GatsbyLink>
    );
  }
}

export function NavBarMenu({ koUrl, enUrl, lang, showLangToggleButton }) {
  const { pathname } = usePathname();
  const items = getMenuItems(lang);
  return (
    <div
      sx={{
        mx: containerMarginX,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {items.map(({ title, href, external }) => (
        <Link
          key={title}
          title={title}
          href={href}
          external={external}
          sx={{
            color: href === pathname ? 'black' : 'gray',
            ml: 32,
          }}
        />
      ))}
      {showLangToggleButton && (
        <LangToggleButton
          sx={{ ml: 48 }}
          koUrl={koUrl}
          enUrl={enUrl}
          lang={lang}
        />
      )}
    </div>
  );
}

export function DropDownMenu({
  lang,
  className,
  toggleMenu,
  showLangToggleButton,
  koUrl,
  enUrl,
}) {
  const { pathname } = usePathname();
  const list = getMenuItems(lang);

  return (
    <div className={className} sx={{ position: 'relative' }}>
      <div
        sx={{
          display: 'flex',
          mt: 14,
          mb: 12,
          mx: 22,
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          type="button"
          ariaLabel="Menu button"
          onClick={toggleMenu}
          sx={{
            width: 20,
            height: 24,
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {showLangToggleButton && (
        <div sx={{ mt: 10, ml: 22 }}>
          <LangToggleButton koUrl={koUrl} enUrl={enUrl} lang={lang} />
        </div>
      )}
      <div sx={{ mt: 32, mx: 22 }}>
        {list.map(({ title, href, external }) => (
          <Link
            key={title}
            title={title}
            href={href}
            external={external}
            sx={{
              display: 'block',
              textDecoration: 'none',
              mb: 20,
              color: href === pathname ? 'black' : 'gray',
            }}
          />
        ))}
      </div>
      <div
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'veryLightGray',
          borderLeftWidth: 0,
          borderRightWidth: 0,
          pt: 26,
          pb: 32,
          pl: 22,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <MadeBy showTitle={false} lang={lang} />
      </div>
    </div>
  );
}
